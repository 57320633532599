let config = {
    API_URL: 'http://localhost:8000',
    STOPM_BROKER: 'ws://localhost:15674/ws',
    AWS_COGNITO_POOL_ID: 'us-east-1_1epcyobz1',
    AWS_COGNITO_CLIENT_ID: '3tial8lpc157s61aiuo94ousuc',
    TRACKING: {
        URL: 'rocket.yuso.io',
        SCRIPT: 'js/',
        SITE_ID: '2'
    },
    DEV: true
};

if (window.location.hostname === 'dev.flex.yuso.io') {
    config.API_URL = 'https://dev.flex.yuso.io'
    config.STOPM_BROKER = 'wss://dev.flex.yuso.io:15674/ws'
    config.DEV = false
}

export default config